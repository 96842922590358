import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import PatientForm from "../opd/appointments-form/PatientForm";
import EditPatientprofile from "../opd/appointments-form/EditPatientprofile";
import ModeContext from "../../context/mode-context";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import HouseIcon from "@mui/icons-material/House";
import ArticleIcon from "@mui/icons-material/Article";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { rxOneApi, rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import Toast from "../ui/Toast";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { Buffer } from "buffer";
import Rxlogo from "../../assets/images/static/rxlogo.svg";
import RxlogoRed from "../../assets/images/static/rxlogo-red.jpg";
import Spinner from "react-bootstrap/Spinner";
import Notification from "./Notification";
import Casefile from "./Casefile";
import Appointmentcase from "./Appointmentcase";

function EditPatientModal({
  show,
  onHide,
  appointmentList,
  selectedProfileData,
  editid,
  prescriptionList,
  selectedPatient,
  setDateRange,
  dateRange,
  onClose,
  loading,
}) {
  const [currentActive, setCurrentActive] = useState(1);
  const [createPatientForm, setCreatePatientForm] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedPres, setSelectedpres] = useState(null);
  const [showPres, setShowPres] = useState(false);
  const { currentHospital } = useContext(HospitalsContext);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [pdfUrl, setPdfurl] = useState();
  const [presPdfUrl, setPresPdfUrl] = useState();
  const [cases, setCases] = useState([]);
  const [showExtraTabs, setShowExtraTabs] = useState(false);
  const getTabs = () => {
    if (showExtraTabs) {
      // Only show Appointments and Case Files tabs when showExtraTabs is true
      return [
        { id: 5, name: "Appointments" },
        { id: 6, name: "Case Files" },
      ];
    }
    // Show default tabs when showExtraTabs is false
    return [
      { id: 1, name: "Profile" },
      { id: 3, name: "Cases" },
      { id: 4, name: "Notifications" },
    ];
  };
  const { mode } = useContext(ModeContext);
  const userKeys = localStorage.getItem("usr_keys");
  const userModeKey = JSON.parse(userKeys)[mode];
  const key = userModeKey[`${mode}_key`];
  const secret = userModeKey[`${mode}_secret`];

  const [selectedFileId, setSelectedFileId] = useState(null);
  const [caseid, setCaseid] = useState(null);
  const [prob, setProb] = useState(null);
  const handleDownloadMedical = async (fileId, caseid, report) => {
    setShowExtraTabs(true);
    setSelectedFileId(fileId);
    setProb(report);
    setCaseid(caseid);
    setCurrentActive(5); // Automatically switch to Case Files tab
  };

  const getCreatedOn = (caseItem) => {
    // Try both versions of the key (with and without space)
    return (
      caseItem["created_on"] || caseItem["created_on "] || "Date not available"
    );
  };
  useEffect(() => {
    const appointment = async () => {
      try {
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS?.HOSPITAL?.PATIENT?.PATIENT_APPOINTMENT +
            "/" +
            currentHospital?.hos_id +
            "/" +
            selectedPatient
        );
        if (res) {
          setCases(res?.data?.cases_list);
        }
      } catch (err) {}
    };
    appointment();
  }, [selectedPatient]);

  const [appointmentData, setAppointmentData] = useState({});
  const keyMapping = {
    patient_name: "Patient Name",
    patient_age: "Patient Age",
    patient_gender: "Patient Gender",
    doc_name: "Doctor's Name",
    reported_problems: "Reported Problems",
    diagnosis: "Diagnosis",
    recommended_tests: "Recommended Tests",
    recommended_medications: "Recommended Medications",
    specific_clinical_findings: "Specific Clinical Findings",
    provisional_diagnosis: "Provisional Diagnosis",
    investigation_reports: "Investigation Reports",
    prescription_notes: "Prescription Notes",
  };
  const newDataObject = {};
  for (const key in appointmentData) {
    if (appointmentData.hasOwnProperty(key)) {
      const displayKey = keyMapping[key] || key;
      newDataObject[displayKey] = appointmentData[key];
    }
  }
  const handleAppointmentClick = async (appointment) => {
    setSelectedAppointment(appointment);
    if (userKeys) {
      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.PATIENT_APPOINTMENT_NOTES +
            "/" +
            currentHospital?.hos_id +
            "/" +
            appointment
        );

        if (res) {
          setAppointmentData(res.data);
        } else {
          throw new Error("Something went wrong. Please try again.");
        }
      } catch (error) {
        setShowToast(error);
        setToastType("error");
        setToastMessage(error?.error?.message || error?.message);
      } finally {
        console.log();
      }
    }
  };

  const handleModalClose = () => {
    setShowExtraTabs(false);
    setSelectedFileId(null);
    setCurrentActive(1);
    onClose();
  };

  const handleBackButtonClick = () => {
    if (showPres) {
      setShowPres(false);
      URL.revokeObjectURL(presPdfUrl);
      setPresPdfUrl("");
    } else {
      setSelectedAppointment(null);
    }
  };
  const handleBackPresClick = () => {
    setSelectedpres(null);
    URL.revokeObjectURL(pdfUrl);
    setPdfurl("");
  };
  /* Filter by date  */
  const handleDateChange = (date, key) => {
    setDateRange((prevRange) => ({
      ...prevRange,
      [key]: date,
    }));
  };
  return (
    <div>
      <Modal centered show={show} onHide={onHide} size="lg">
        <Modal.Header
          className="p-2 text-white border-0"
          closeButton
          onClick={handleModalClose}
          style={{ backgroundColor: "#00b0f0" }}
        >
          <Modal.Title>
            {selectedAppointment ? (
              <>
                <span
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    alignContent: "center",
                    width: "100%",
                    textTransform: "uppercase",
                  }}
                >
                  {appointmentData?.Name} ({appointmentData?.Gender}
                  {appointmentData?.Age})
                </span>
              </>
            ) : (
              <>
                {loading === 2 ? (
                  <>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </>
                ) : (
                  <>
                    <span style={{ textTransform: "uppercase" }}>
                      {selectedProfileData?.name} ({selectedProfileData?.gender}
                      ,{selectedProfileData?.age})
                    </span>
                  </>
                )}
              </>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="m-0 p-0">
          <div className="px-0 m-0 w-100 container-fluid">
            <Row className="mx-0">
              <Col xs={12} md={2} className="p-0  d-flex flex-md-column">
                {getTabs().map((item) => (
                  <div
                    key={item.id}
                    className="p-3 m-0 update-user-modal text-center text-md-start"
                    style={{
                      backgroundColor:
                        currentActive === item.id ? "#D3D3D3" : "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setCurrentActive(item.id)}
                  >
                    {item.name}
                  </div>
                ))}
              </Col>

              <Col>
                {loading !== 0 ? (
                  <>
                    <Spinner
                      style={{ position: "fixed", top: "50%", left: "50%" }}
                      animation="border"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </>
                ) : (
                  <div
                    style={{
                      minHeight: "500px",
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                  >
                    {currentActive === 5 ? (
                      <Appointmentcase
                        fileId={selectedFileId}
                        selectedPatient={selectedPatient}
                        setShowExtraTabs={setShowExtraTabs}
                        setCurrentActive={setCurrentActive}
                        caseid={caseid}
                        reported={prob}
                      />
                    ) : currentActive === 6 ? (
                      <Casefile
                        fileId={selectedFileId}
                        selectedPatient={selectedPatient}
                        setShowExtraTabs={setShowExtraTabs}
                        setCurrentActive={setCurrentActive}
                        caseid={caseid}
                        reported={prob}
                      />
                    ) : currentActive === 1 ? (
                      <>
                        <EditPatientprofile
                          addToExistingProfile={true}
                          show={createPatientForm}
                          onHide={() => setCreatePatientForm(false)}
                          type="patient"
                          data={selectedProfileData}
                          editid={editid}
                          heading={false}
                        />
                      </>
                    ) : currentActive === 3 ? (
                      <>
                        {selectedPres ? (
                          <div style={{ minHeight: "400px" }}>
                            <div className="d-flex justify-content-end align-items-center">
                              <button
                                type="button"
                                className="btn btn-light mb-2"
                                onClick={handleBackPresClick}
                              >
                                {"<"} Back
                              </button>
                            </div>
                            <div style={{ height: "500px", width: "100%" }}>
                              <object
                                data={pdfUrl}
                                type="application/pdf"
                                style={{ height: "500px", width: "100%" }}
                              >
                                An error occurred while fetching the file,
                                please try again later
                              </object>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <h6
                              style={{ marginTop: "1rem", marginLeft: "1rem" }}
                            >
                              Click for case details :
                            </h6>

                            {cases?.length > 0 ? (
                              cases.map((caseItem) => {
                                console.log("caseItem", caseItem);
                                return (
                                  <div
                                    key={caseItem.case_id}
                                    className="row shadow p-2 justify-content-center"
                                    style={{
                                      borderRadius: "20px",
                                      margin: "8px",
                                      minHeight: "100px",
                                      textAlign: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      handleDownloadMedical(
                                        caseItem.case_file_id,
                                        caseItem.case_id,
                                        caseItem.reported_problem
                                      );
                                    }}
                                  >
                                    <div className="col-sm-12 col-md-4">
                                      <p
                                        className="mb-0 font-weight-bold"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Case ID#
                                      </p>
                                      <p className="font-weight-bold">
                                        {caseItem.case_id || ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                      <p
                                        className="text-gray-600 text-sm mb-1 font-weight-bold"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Created On
                                      </p>
                                      <p className="font-weight-bold">
                                        {getCreatedOn(caseItem)}
                                      </p>
                                    </div>
                                    <div className="col-sm-12 col-md-4">
                                      <p
                                        className="text-gray-600 text-sm mb-1 font-weight-bold"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Reported Problem:
                                      </p>
                                      <p className="font-weight-bold">
                                        {caseItem.reported_problem ||
                                          "No problem reported"}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p>No Data Found</p>
                            )}
                          </div>
                        )}
                      </>
                    ) : currentActive === 4 ? (
                      <>
                        <Notification editid={editid} />
                      </>
                    ) : (
                      <h1></h1>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </div>
  );
}

export default EditPatientModal;
