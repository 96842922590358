import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  Divider,
  Select as MuiSelect,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Notifications, Email, Phone, Message } from "@mui/icons-material";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import ModeContext from "../../context/mode-context";
import Select from "react-select";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { countriesList } from "../Countrylist";
import dayjs from "dayjs"; // Make sure dayjs is imported
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const NotificationForm = ({ onClose, editid, fetchNotifications }) => {
  const { mode } = useContext(ModeContext);
  const [formData, setFormData] = useState({
    notificationType: "order_ready", // Default value
    date: null,
    time: null,
    message: "",
    phoneNumber: "",
    email: "",
    smsEnabled: true,
    whatsappEnabled: true,
    hospitalPhoneNumber: "",
  });

  // Add state for message error
  const [messageError, setMessageError] = useState("");
  // Add validation states
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [errors, setErrors] = useState({
    hospitalPhoneNumber: "",
    // ...existing errors...
  });
  const {
    isLoading,
    currentHospital,
    changeCurrentHospital,
    hospitals,
    noHospsMessage,
  } = useContext(HospitalsContext);
  const [hosid, setHosid] = useState("");
  const notificationTypes = [
    { value: "order_ready", label: "Order ready notification" },
    // Add more notification types here if needed
  ];

  const [selectedDocId, setSelectedDocId] = useState({
    label: "All Doctors",
    value: "all",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [phoneCode, setPhoneCode] = useState("+91");
  const handleCountryChange = (event) => {
    const newCode = event.target.value;
    setPhoneCode(newCode);
  };

  const handleHospitalChange = ({ value }) => {
    setHosid(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // If email is empty and SMS is disabled, don't show error
    if (!email && !formData.smsEnabled) {
      setEmailError("");
      return true;
    }
    // Only validate if there is an email entered
    if (email) {
      if (!emailRegex.test(email)) {
        setEmailError("Invalid email format");
        return false;
      }
    }
    setEmailError("");
    return true;
  };

  // Phone validation function
  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/; // Assumes 10 digit phone number
    if (!phone) {
      setPhoneError("Phone number is required");
      return false;
    }
    if (!phoneRegex.test(phone)) {
      setPhoneError("Phone number must be 10 digits");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const formatDateTime = (date, time) => {
    if (!date || !time) return null;
    const formattedDate = date.format("YYYY-MM-DD");
    const formattedTime = time.format("HH:mm");
    return `${formattedDate} ${formattedTime}`;
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    // Add validation for message field
    if (field === "message") {
      if (value.length > 32) {
        value = value.slice(0, 32);
        setMessageError("Maximum 32 characters allowed");
      } else {
        setMessageError("");
      }
    }

    // Add validation for email field
    if (field === "email") {
      validateEmail(value);
    }

    // Add validation for phone field
    if (field === "phoneNumber") {
      validatePhone(value);
    }

    setFormData({
      ...formData,
      [field]: value,
    });
    if (field === "hospitalPhoneNumber") {
      const phoneNumberPattern = /^[0-9]{10}$/; // Example pattern for a 10-digit phone number
      if (!phoneNumberPattern.test(value)) {
        setErrors({
          ...errors,
          hospitalPhoneNumber: "Invalid phone number",
        });
      } else {
        setErrors({
          ...errors,
          hospitalPhoneNumber: "",
        });
      }
    }
  };

  const handleSwitchChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.checked,
    });
  };

  const handleDateChange = (newDate) => {
    setFormData({
      ...formData,
      date: newDate,
    });
  };

  const handleTimeChange = (newTime) => {
    setFormData({
      ...formData,
      time: newTime,
    });
  };

  const fetchNotification = async () => {
    try {
      const res = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.NOTIFICATION_DETAILS}/${currentHospital.hos_id}/${editid}`
      );

      setFormData((prevState) => ({
        ...prevState,
        phoneNumber: res.data.patient_phone_number || "",
        email: res.data.patient_email_id || "",
        hospitalPhoneNumber: res.data.hospital_phone_number || "",
      }));
    } catch (err) {
      console.error("Error fetching notification details:", err);
    }
  };

  useEffect(() => {
    if (currentHospital?.hos_id && editid) {
      fetchNotification();
    }
  }, [currentHospital?.hos_id, editid]);

  const handleSubmit = async () => {
    let isEmailValid = true;
    
    // Validate email if SMS is enabled or email is provided
    if (formData.smsEnabled || formData.email.trim() !== "") {
        isEmailValid = validateEmail(formData.email);
    }

    const isPhoneValid = validatePhone(formData.phoneNumber);
    
    if (!isPhoneValid) {
        return;
    }

    setIsSubmitting(true);

    try {
        const selectedHospitalId = hosid || currentHospital?.hos_id;

        // Check if we have a valid hospital ID
        if (!selectedHospitalId) {
            console.error("No hospital selected");
            return;
        }

        const body = {
            email: formData?.smsEnabled,
            whatsapp: formData?.whatsappEnabled,
            date_time: formatDateTime(formData?.date, formData?.time),
            order_input: formData?.message,
            phone_number: formData?.whatsappEnabled === false? "" : phoneCode + formData?.phoneNumber,
            hospital_phone_number: phoneCode + formData?.hospitalPhoneNumber,
            email_id: formData?.smsEnabled === false ? "" : formData?.email, // Set email_id to empty string if validation fails
        };

        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];
        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        rxOpdApi.setMultipartHeaders();

        const response = await rxOpdApi.post(
            `${RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.ADD_NOTIFICATION}/${selectedHospitalId}/${editid}`,
            body
        );

        if (response) {
            setIsSubmitting(false);
            onClose();
            fetchNotifications();
        }
    } catch (err) {
      setIsSubmitting(false);
        console.error("Error submitting notification:", err);
    }
};

  useEffect(() => {
    if (!formData.date || !formData.time) {
      setFormData((prev) => ({
        ...prev,
        date: dayjs(),
        time: dayjs(),
      }));
    }
  }, []);

  // Check if the submit button should be disabled
  const isSubmitDisabled =
    !formData.message.trim() || // Message is required
    isSubmitting || !formData.whatsappEnabled && !formData.smsEnabled;
  return (
    <Card className="shadow-lg rounded-lg">
      <CardContent className="p-6">
        <div className="" onClick={onClose} style={{ cursor: "pointer" }}>
          <ArrowBackIosIcon />
          <Typography variant="4" className="">
            Back
          </Typography>
        </div>
        <Divider className="mb-6" style={{ backgroundColor: "none" }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl
            size="small"
            sx={{ width: "250px", marginBottom: "16px", marginTop: "1rem" }}
          >
            <InputLabel>Notification</InputLabel>
            <MuiSelect
              value={formData.notificationType}
              onChange={handleChange("notificationType")}
              label="Notification Type"
            >
              {notificationTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
          <InputLabel sx={{ marginBottom: "8px", fontSize: "0.875rem" }}>
            Custom Message
          </InputLabel>
          <Box className="space-y-6">
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                padding: "16px",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                background: "#f2eded",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    paddingTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  Please visit
                </Typography>
                {/* Hospital Selection */}
                <Box sx={{ flex: 2 }}>
                  <FormControl fullWidth size="small">
                    <Select
                      defaultValue={{
                        label: (
                          <div className="flex items-center">
                            <img
                              src={`${process.env.REACT_APP_RX_OPD}${
                                mode === "test" ? "test/" : ""
                              }${RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO}/${
                                currentHospital?.hos_id
                              }?v=${Math.random() * Math.random()}`}
                              height="24px"
                              width="24px"
                              style={{ marginRight: "8px" }}
                            />
                            {currentHospital?.hosp_name}
                          </div>
                        ),
                        value: currentHospital?.hos_id,
                      }}
                      options={hospitals?.map((hospital) => ({
                        label: (
                          <div className="flex items-center">
                            <img
                              src={`${process.env.REACT_APP_RX_OPD}${
                                mode === "test" ? "test/" : ""
                              }${RX_OPD_ENDPOINTS.HOSPITAL.GET_HOSPITAL_LOGO}/${
                                hospital?.hos_id
                              }?v=${Math.random() * Math.random()}`}
                              height="24px"
                              width="24px"
                              style={{ marginRight: "8px" }}
                            />
                            {hospital?.hosp_name}
                          </div>
                        ),
                        value: hospital?.hos_id,
                      }))}
                      onChange={handleHospitalChange}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          minHeight: "40px",
                          height: "40px",
                          border: "1px solid #b3c6e7",
                          borderRadius: "4px",
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </FormControl>
                </Box>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    paddingTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  by
                </Typography>

                {/* Date and Time */}
                <Box sx={{ flex: "1" }}>
                  <DatePicker
                    label="Date"
                    value={formData.date}
                    onChange={handleDateChange}
                    defaultValue={dayjs()}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: { height: "40px" },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ flex: "1" }}>
                  <TimePicker
                    label="Time"
                    value={formData.time}
                    onChange={handleTimeChange}
                    defaultValue={dayjs()}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: { height: "40px" },
                      },
                    }}
                  />
                </Box>
              </Box>
              {/* Message Field */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    paddingTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  your
                </Typography>
                <Box sx={{ width: "300px", height: "10%" }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="type your own"
                    multiline
                    rows={2}
                    value={formData.message}
                    onChange={handleChange("message")}
                    variant="outlined"
                    error={!!messageError}
                    helperText={
                      messageError || `${formData.message.length}/32 characters`
                    }
                    inputProps={{
                      maxLength: 32,
                    }}
                  />
                </Box>
                <InputLabel
                  sx={{
                    fontSize: "0.875rem",
                    paddingTop: "8px",
                    whiteSpace: "nowrap",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Order is now ready
                </InputLabel>
              </Box>

              {/* Hospital Contact */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  For any queries with respect to your order please contact
                </InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    flex: 1,
                  }}
                >
                  <Select
                    options={countriesList.map((item) => ({
                      value: item.code,
                      label: `${item.code} `,
                    }))}
                    value={{ value: phoneCode, label: phoneCode }}
                    onChange={(selectedOption) =>
                      setPhoneCode(selectedOption.value)
                    }
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        minHeight: "40px",
                        height: "40px",
                        border: "1px solid rgb(145, 173, 221)",
                        borderRadius: "4px",
                        width: "54px",
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        width: "54px",
                        zIndex: 9999,
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "#f0f0f0" : "white",
                        color: "#333",
                        padding: "2px 12px",
                      }),
                    }}
                    placeholder="Select a country code"
                  />
                  <TextField
                    size="small"
                    label="Hospital Phone Number"
                    value={formData.hospitalPhoneNumber}
                    onChange={handleChange("hospitalPhoneNumber")}
                    type="tel"
                    variant="outlined"
                    error={!!errors.hospitalPhoneNumber}
                    helperText={errors.hospitalPhoneNumber}
                    sx={{
                      "& .MuiInputBase-root": { height: "40px" },
                      flex: 1,
                      marginLeft: "-10px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {/* Notification Preferences */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  whiteSpace: "nowrap",
                  fontWeight: "700",
                  color: "black",
                }}
              >
                Preferences :
              </Typography>
              <Box
                className="space-y-2"
                style={{ display: "flex", gap: "42px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px", // Space between icon and switch
                  }}
                >
                  <EmailIcon
                    sx={{
                      fontSize: "30px",
                      color: "blue",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.smsEnabled}
                        onChange={handleSwitchChange("smsEnabled")}
                        size="small"
                      />
                    }
                    label="" // Removing the label since we're using standalone icon
                    sx={{ margin: 0 }} // Remove default margin
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px", // Space between icon and switch
                  }}
                >
                  <WhatsAppIcon
                    sx={{
                      fontSize: "30px",
                      color: "green",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.whatsappEnabled}
                        onChange={handleSwitchChange("whatsappEnabled")}
                        size="small"
                      />
                    }
                    label="" // Removing the label since we're using standalone icon
                    sx={{ margin: 0 }} // Remove default margin
                  />
                </Box>
              </Box>
            </Box>

            {/* Contact Details */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
                marginTop: "1rem",
              }}
            >
              <InputLabel
                sx={{
                  // fontSize: "0.875rem",
                  whiteSpace: "nowrap",
                  fontWeight: "700",
                  color: "black",
                }}
              >
                Contact Details:
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  "& .MuiTextField-root": { height: "40px" },
                  "& .MuiInputBase-root": { height: "40px" },
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Email"
                  value={formData.email}
                  onChange={handleChange("email")}
                  error={!!emailError}
                  helperText={
                    !formData.smsEnabled && formData.email.trim()
                      ? ""
                      : emailError
                  }
                  type="email"
                  variant="outlined"
                  required={formData.smsEnabled}
                  disabled={!formData.smsEnabled}
                />
                <Select
                  options={countriesList.map((item) => ({
                    value: item.code,
                    label: `${item.code} `,
                  }))}
                  value={{ value: phoneCode, label: phoneCode }}
                  onChange={(selectedOption) =>
                    setPhoneCode(selectedOption.value)
                  }
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minHeight: "40px",
                      height: "40px",
                      border: "1px solidrgb(145, 173, 221)",
                      borderRadius: "4px",
                      width: "90px", // Adjust the width here
                      marginLeft: "1rem",
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      width: "90px", // Ensure the dropdown matches the control width
                      zIndex: 9999,
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? "#f0f0f0" : "white",
                      color: "#333",
                      padding: "2px 12px",
                    }),
                  }}
                  placeholder="Select a country code"
                />

                <TextField
                  fullWidth
                  size="small"
                  label="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange("phoneNumber")}
                  error={!!phoneError}
                  helperText={phoneError}
                  type="tel"
                  variant="outlined"
                  required
                  inputProps={{
                    maxLength: 10,
                    pattern: "[0-9]*",
                  }}
                  disabled={!formData.whatsappEnabled}

                />
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </CardContent>

      <Divider />

      <CardActions className="p-4 flex justify-center gap-2 bg-gray-50">
        {/* <Button
          size="small"
          onClick={onClose}
          variant="outlined"
          className="text-gray-600 hover:bg-gray-100"
          disabled={isSubmitting}
        >
          Cancel
        </Button> */}
        <Button
          size="small"
          onClick={handleSubmit}
          variant="contained"
          className="bg-blue-600 hover:bg-blue-700 text-white"
          disabled={isSubmitting || isSubmitDisabled}
          startIcon={
            isSubmitting ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          {isSubmitting ? "Sending..." : "Send"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default NotificationForm;
