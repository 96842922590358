export const bioMarks = [
    "Height",
    "Weight",
    "Head Circumference",
    "Body Temperature",
    "SPO2 Level",
    "Blood Glucose",
    "Blood Pressure",
    "Heart Rate",
  ]; 
  
  export const bioMarksBloodPressure = {
    systolic: "Blood Pressure - Systolic",
    diastolic: "Blood Pressure - Diastolic",
    default: "Blood Pressure",
  };