import { useState, useEffect, useContext } from "react";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import HospitalsContext from "../../context/hospitals-context";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import Spinner from "react-bootstrap/Spinner";

import {
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  tableCellClasses,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material";
const Appointmentcase = ({
  fileId,
  selectedPatient,
  setShowExtraTabs,
  setCurrentActive,
  caseid,
  reported,
}) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const { currentHospital } = useContext(HospitalsContext);
  const [list, setList] = useState([]);
  const [loadingAppointmentDetails, setLoadingAppointmentDetails] = useState(false);

  useEffect(() => {
    const fetchappointment = async () => {
      setLoadingAppointmentDetails(true);
      try {
        const res = await rxOpdApi.get(
          RX_OPD_ENDPOINTS?.HOSPITAL?.PATIENT?.PATIENT_APPOINTMENT_CASE +
            "/" +
            currentHospital?.hos_id +
            "/" +
            selectedPatient +
            "/" +
            fileId
        );
        if (res) {
          setLoadingAppointmentDetails(false);
          setList(res.data.appointment_list);
        }
      } catch (err) {
        setLoadingAppointmentDetails(false);
        console.log(err);
      }
    };
    fetchappointment();
  }, [selectedPatient, fileId]);

  const handleback = () => {
    setShowExtraTabs(false);
    setCurrentActive(3);
  };

  return (
    <div className="">
      <div onClick={handleback} className="mt-3">
        <ArrowBackIosOutlined style={{ cursor: "pointer" }} />
        Back
      </div>
      <div className="row shadow p-2 justify-content-center">
      <div className="col-sm-12 col-md-4">
        <p className="mb-0" style={{ fontWeight: "600" }}>Case ID#</p>
        <p className="font-bold">{caseid}</p>
      </div>
      <div className="col-sm-12 col-md-6">
        <h6>Reported Problem:</h6>
        <p>{reported || "No problem reported"}</p>
      </div>
    </div>

    {!loadingAppointmentDetails ? (
      <TableContainer
        component={Paper}
        style={{ marginBottom: ".5rem", marginTop: "1rem" }}
      >
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ textAlign: "center" }}>
                Date
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Time
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Type
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Prescription
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              Array.isArray(list) &&
              list.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    {row?.date || ""}
                  </StyledTableCell>

                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {row?.time_alloted || ""}
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.app_type || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.prescription_status || ""}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

) : (
  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
    <Spinner
      as="span"
      animation="border"
      size="md"
      role="status"
      aria-hidden="true"
      className="mx-auto my-5"
    />
  </div>
)}
    </div>
  );
};
export default Appointmentcase;
