import React, { useState, useEffect, useContext } from "react";
import HospitalsContext from "../../context/hospitals-context";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import Button from "../ui/Button";
import DateRange from "../date-range/DateRange";
import dayjs from "dayjs";
import Col from "react-bootstrap/Col";
import {
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  tableCellClasses,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material";
import NotificationForm from "./NotificationForm";
import { CheckBox } from "@mui/icons-material";

const MessageCell = ({ message }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const truncateText = (text) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= 5) return text;
    return isExpanded ? text : words.slice(0, 5).join(" ") + "...";
  };

  return (
    <StyledTableCell
      // style={{
      //   textAlign: "center",
      //   // maxWidth: "200px", // Fixed width
      //   whiteSpace: isExpanded ? "normal" : "nowrap",
      //   overflow: "hidden",
      //   textOverflow: "ellipsis",
      //   verticalAlign: "top",
      //   position: "relative",
      // }}
    >
      <div
        style={{
          wordBreak: "break-word",
          minHeight: isExpanded ? "auto" : "20px",
        }}
      >
        {truncateText(message || "")}
        {message && message.split(" ").length > 5 && (
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-500 hover:text-blue-700 underline text-sm"
            style={{
              cursor: "pointer",
              color: "green",
              marginTop: "4px",
              position: "relative",
            }}
          >
            {isExpanded ? "Show less" : "Show more"}
          </div>
        )}
      </div>
    </StyledTableCell>
  );
};

const Notification = ({ editid }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const { currentHospital } = useContext(HospitalsContext);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const currentDate = dayjs();
  const defaultStartDate = currentDate.subtract(3, "day");
  const defaultEndDate = currentDate.add(1, "day");
  const [dateRange, setDateRange] = useState({
    from: defaultStartDate,
    to: defaultEndDate,
  });

  const endDateVal = new Date();
  const startDateVal = new Date();
  startDateVal.setDate(endDateVal.getDate());
  endDateVal.setDate(endDateVal.getDate() + 1);
  const [startDate, setStartDate] = useState(startDateVal);
  const [endDate, setEndDate] = useState(endDateVal);

  // const truncateText = (text) => {
  //   if (!text) return "";
  //   const words = text.split(" ");
  //   if (words.length <= 5) return text;
  //   return isExpanded ? text : words.slice(0, 5).join(" ") + "...";
  // };

  const fetchNotification = async () => {
    setLoading(true);
    try {
      // Format dates properly
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const response = await rxOpdApi.get(
        `${RX_OPD_ENDPOINTS.HOSPITAL.PATIENT.NOTIFICATION}/${
          currentHospital.hos_id
        }/${editid}?start_date=${formatDate(startDate)}&end_date=${formatDate(
          endDate
        )}`
      );
      if (response && response.data) {
        setNotifications(response.data.patient_notifications_list);
      }
    } catch (err) {
      console.error("Error fetching notifications:", err);
      setError(err.message || "Failed to fetch notifications");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNotification();
  }, [editid, currentHospital?.hos_id, startDate, endDate]);

  const handlesub = () => {
    setIsFormOpen(true);
  };

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  const StatusCell = ({ value }) => {
    return value ? (
      <div className="flex justify-center">
        <CheckBox className="text-green-600" size={20} />
      </div>
    ) : null;
  };

  return (
    <div className="">
      {isFormOpen ? (
        <NotificationForm
          onClose={handleFormClose}
          editid={editid}
          fetchNotifications={fetchNotification}
        />
      ) : (
        <>
          <Col
            xs={12}
            lg={8}
            className="d-flex mt-3 mt-lg-0 align-items-center position-relative"
          >
            <DateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              label=""
            />
          </Col>

          <Button onClick={handlesub}>Send+</Button>

          <TableContainer
            component={Paper}
            style={{ marginBottom: ".5rem", marginTop: "1rem" }}
          >
            <Table sx={{ minWidth: 300 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Date
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Time
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Message
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Email id
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Phone
                  </StyledTableCell>
                  {/* <StyledTableCell style={{ textAlign: "center" }}>
                    Hospital No.
                  </StyledTableCell> */}
                  <StyledTableCell style={{ textAlign: "center" }}>
                    What's App
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Email
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications &&
                  Array.isArray(notifications) &&
                  notifications.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        {row?.created_on_date || ""}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ textAlign: "center" }}
                      >
                        {row?.created_on_time || ""}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        <MessageCell message={row?.message} />
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        {row?.email_id || ""}
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        {row?.patient_phone || ""}
                      </StyledTableCell>
                      {/* <StyledTableCell style={{ textAlign: "center" }}>
                        {row?.hospital_phone_number || ""} 
                      </StyledTableCell> */}
                      <StyledTableCell style={{ textAlign: "center" }}>
                        <StatusCell value={row?.whatsapp_notification} />
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        <StatusCell value={row?.email_notification} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default Notification;
