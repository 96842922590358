import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";

function PatientTab(props) {
  const {
    registeredPatient,
    isLoadingPatients,
    handleShow,
    setSelectedPatient,
    handleDownloadPatient,
  } = props;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    console.log("Current registeredPatient length:", registeredPatient.length);
    console.log("Current pagination model:", paginationModel);
  }, [registeredPatient, paginationModel]);

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      headerAlign: "left",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "age",
      headerName: "AGE",
      width: 80,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "gender",
      headerName: "GENDER",
      description: "This column has a value getter and is not sortable.",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "phone",
      headerName: "PHONE",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <a
          href={`tel:${params.value}`}
          style={{ textDecoration: "none", fontSize: "12px" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "email",
      headerName: "MAIL",
      width: 200,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <a
          href={`mailto:${params.value}`}
          style={{ textDecoration: "none", fontSize: "12px" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "address",
      headerName: "ADDRESS",
      width: 340,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
  ];

  useEffect(() => {
    setHasMore(registeredPatient.length === 20);
  }, [registeredPatient]);

  const handlePaginationChange = (model) => {
    setPaginationModel(model);
    
    if (hasMore && model.page > paginationModel.page) {
      handleDownloadPatient(undefined, model.page + 1);
    }
  };


  return (
    <>
      <Box
      sx={{
        height: 430,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#fafafa",
          fontSize: "0.9rem",
          fontWeight: "bold",
          textAlign: "left",
        },
      }}
    >
    <DataGrid
        rows={registeredPatient}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[20, 50, 100]}
        getRowId={(row) => row.profile_id}
        onRowClick={(row) => {
          setSelectedPatient(row.id);
          handleShow();
        }}
        rowSelection={false}
        loading={isLoadingPatients}
        rowCount={hasMore ? (paginationModel.page + 2) * 20 : registeredPatient.length}
        paginationMode="server"
      />
    </Box>
    </>
  );
}

export default PatientTab;
