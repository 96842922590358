import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import HospitalsContext from "../../../context/hospitals-context";
import ModeContext from "../../../context/mode-context";
import AppointmentsFormContext from "../../../context/appointments-form";
import FormControl from "../../form/FormControl";
import InputErrorMessage from "../../kyc/InputErrorMessage";
import Button from "../../ui/Button";
import Toast from "../../ui/Toast";
import { rxOpdApi } from "../../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../../utils/api/apiEndPoints";
import StateListContext from "../../../context/cityNstate-context";
import Creatable from "react-select/creatable";

function calculateAge(birthDate) {
  if (!(birthDate instanceof Date) || isNaN(birthDate)) {
    return ""; // Return empty string for invalid date
  }

  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  if (
    today.getMonth() < birthDateObj.getMonth() ||
    (today.getMonth() === birthDateObj.getMonth() &&
      today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
}

function calculateBirthDate(age) {
  const today = new Date();
  const birthDate = new Date(
    today.getFullYear() - age,
    today.getMonth(),
    today.getDate()
  );
  return birthDate;
}
function EditProfile(props) {
  //min date can be 120 year ago and max date is todays date
  const maxDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 120);
  const {
    onHide,
    setChangeStep,
    setJumpToStep,
    editid,
    profileTypes,
    genderOptions,
    setupdatecalModal,
  } = props;
  // const { stateList, cityList } = useContext(StateListContext);

  const { mode } = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const { setNewProfileAdded } = useContext(AppointmentsFormContext);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [profileCreated, setProfileCreated] = useState(false);

  useEffect(() => {
    if (!showToast && profileCreated) {
      onHide();
      setChangeStep(true);
      setJumpToStep(0);
    }
  }, [showToast, profileCreated, onHide]);

  const fields = [
    "profile_type",
    "name",
    "gender",
    "email",
    "birth_date",
    "address",
    "city",
    "state",
    "country",
    "pinCode",
  ];
  const errors = [];

  const formikProps = useFormikContext();
  const checkValidation = async (field) => {
    const error = await formikProps.setFieldTouched(`patientProfile.${field}`);

    if (Object.keys(error).length > 0) errors.push(error);
  };

  const handleRegister = async () => {
    setIsRegistering(true);

    try {
      for (const field of fields) {
        await checkValidation(field);
      }

      if (errors.length > 0) return;

      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];

      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      const values = formikProps.values.patientProfile;
      const birth_date =
        values.birth_date.getFullYear() +
        "-" +
        (+values.birth_date.getMonth() + 1) +
        "-" +
        values.birth_date.getDate();
      rxOpdApi.setAuthHeaders(key, secret);
      const res = await rxOpdApi.put(
        RX_OPD_ENDPOINTS.HOSPITAL.OPD.PATIENT_INFO +
          "/" +
          currentHospital.hos_id +
          "/" +
          editid,
        {
          profile_type: values.profile_type.value
            ? values.profile_type.value
            : "",
          email: values.email ? values.email : "",
          name: values.name ? values.name : "",
          gender: values.gender.value ? values.gender.value : "",
          birth_date: birth_date ? birth_date : "",
          address: values.address ? values.address : "",
          city: values.city.value ? values.city.value : "",
          state: values.state.value ? values.state.value : "",
          pincode: values.pinCode ? values.pinCode : "",
          country: values.country.label ? values.country.label : "",
        }
      );

      if (res) {
        setShowToast(true);
        setToastType("success");
        setToastMessage(res.data.message);
        setupdatecalModal(res.data.message);
        setProfileCreated(true);
        // if (addToExistingProfile) {
        setNewProfileAdded(true);
        // }
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setIsRegistering(false);
    }
  };
  /* handle country change */
  const formikprops = useFormikContext();
  const countryOptions = [{ value: "IN", label: "India" }];
  const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]); // Default selected country is India

  // Function to handle country selection change
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    fetchStates(selectedOption.value);
    fetchCity(selectedOption.value);
    formikprops.setFieldValue("patientProfile.country", selectedOption);
  };
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const fetchStates = async (country) => {
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/states/${country}`
      );

      if (res) {
        setStateList(res.data.states);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const fetchCity = async (country, state) => {
    console.log("state", state);
    try {
      const res = await rxOpdApi.get(
        `https://rx-one-dev-iroek.ondigitalocean.app/rxone/list/cities/${country}${state}`
      );

      if (res) {
        setCityList(res.data.cities);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleState = (selectedCountry, selectedState) => {
    console.log("selectedCountry", selectedCountry);
    console.log("selectedState", selectedState);
    fetchCity(selectedCountry.value, selectedState);
  };

  const options = stateList.map((state) => ({ label: state, value: state }));
  const optionscity = cityList.map((city) => ({ label: city, value: city }));
  //  *******************aage code

  const handleDateOfBirthChange = (date) => {
    // Calculate age based on the selected date of birth
    const age = calculateAge(date);

    // Set the age value in the form
    formikProps.setFieldValue("age", age);

    // Set the date of birth value in the form
    formikProps.setFieldValue("patientProfile.birth_date", date);
  };

  // ... (existing code)

  const handleAgeChange = (age) => {
    // Calculate birth date based on age
    const birthDate = calculateBirthDate(age);

    // Set the birth date value in the form
    formikProps.setFieldValue("patientProfile.birth_date", birthDate);

    // Set the age value in the form
    formikProps.setFieldValue("age", age);
  };

  useEffect(() => {
    const initialDateOfBirth = formikProps.values.patientProfile.birth_date;
  
    // Check if Date of Birth is already populated
    if (initialDateOfBirth) {
      const initialAge = calculateAge(initialDateOfBirth);
      formikProps.setFieldValue("age", initialAge);
    }
  }, []);
  return (
    <>
      <div className="my-1 row align-items-center w-100 mx-0">
        <div className="patient-profile-ele px-0 w-100">
          <label className="accreditationBodyLabel px-0 pe-md-3 col-11 col-md-12 w-100">
            {/* d-flex text-end justify-content-start justify-content-md-end align-items-center"> */}
            {/* {`${
            businessType === "individual"
              ? "Owner ID Card Type"
              : "Signatory ID Card"
          }`} */}
            Profile Type
          </label>
          {/* <input className="w-100" /> */}

          <Field
            name="patientProfile.profile_type"
            className="col-12 col-md-12"
          >
            {({ field, form }) => (
              <Select
                {...field}
                // isDisabled={isMainDataSaved || areDocsSubmitted}
                options={profileTypes}
                name="patientProfile.profile_type"
                className="col-12 col-md-12 px-0"
                onChange={(option) => {
                  form.setFieldValue(
                    "patientProfile.profile_type",
                    option,
                    true
                  );
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                    padding: "0",
                    margin: "0",
                  }),
                  // menuList: menustyles => ({
                  //   ...menustyles,
                  //   height: "100px",
                  // }),
                }}
              />
            )}
          </Field>
        </div>
      </div>

      <ErrorMessage
        component={InputErrorMessage}
        name="patientProfile.profile_type"
      />

      <FormControl
        label="Name"
        type="text"
        name="patientProfile.name"
        labelColClass="col-12 justify-content-start mb-0"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />

      <FormControl
        label="Email"
        type="email"
        name="patientProfile.email"
        labelColClass="col-12 justify-content-start mb-0"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />

      <div className="my-1 row align-items-center w-100 mx-0">
        <div className="px-0">
          <label className="accreditationBodyLabel px-0 pe-md-3 col-11 col-md-12 w-100">
            {/* d-flex text-end justify-content-start justify-content-md-end align-items-center"> */}
            {/* {`${
            businessType === "individual"
              ? "Owner ID Card Type"
              : "Signatory ID Card"
          }`} */}
            Gender
          </label>

          {/* <input className="w-100" /> */}

          <Field name="patientProfile.gender" className="col-12 col-md-12">
            {({ field, form }) => (
              <Select
                {...field}
                // isDisabled={isMainDataSaved || areDocsSubmitted}
                options={genderOptions}
                name="patientProfile.gender"
                className="col-12 col-md-12 px-0"
                onChange={(option) => {
                  form.setFieldValue("patientProfile.gender", option, true);
                }}
                styles={{
                  control: (controlStyles) => ({
                    ...controlStyles,
                    border: "2px solid #b3c6e7",
                    borderRadius: "0",
                    padding: "0",
                    margin: "0",
                  }),
                  // menuList: menustyles => ({
                  //   ...menustyles,
                  //   height: "100px",
                  // }),
                }}
              />
            )}
          </Field>
        </div>
      </div>

      <ErrorMessage
        component={InputErrorMessage}
        name="patientProfile.gender"
      />

      {/* *************************************************************** */}
      <div className="d-flex ">
        <div>
          {/* Age */}
          <label className="ms--12 mt-1 mx-0">Age</label>
          <Field name="age" type="text">
            {({ field }) => (
              <input
                {...field}
                type="text"
                className="form-control"
                onChange={(e) => handleAgeChange(e.target.value)}
                onKeyDown={(e) => {
                  // Allow only numeric keys and certain control keys
                  const allowedKeys = [
                    "Backspace",
                    "Tab",
                    "Enter",
                    "Delete",
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                  ];
                  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter age"
              />
            )}
          </Field>
        </div>
        <text style={{ marginTop: "30px" }} className="mx-5">
          Or
        </text>
        <div>
          {/* // Date of Birth */}
          <label className="ms--12 mt-1 mx-0">Date of Birth</label>
          <Field name="patientProfile.birth_date">
            {({ field, form }) => (
              <DatePicker
                {...field}
                id="birth_date"
                name="patientProfile.birth_date"
                wrapperClassName="col-12 px-0 py-2 py-sm-0 mb-1 mx-0"
                className="mx-0"
                selected={form.values.patientProfile.birth_date}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={maxDate}
                minDate={minDate}
                onChange={(val) => handleDateOfBirthChange(val)}
                dateFormat="yyyy-MM-dd"
              />
            )}
          </Field>
        </div>
      </div>

      {/* ************************************************************* */}

      <FormControl
        label="Address"
        type="text"
        name="patientProfile.address"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />
      <span className="ms--12 mt-1 mx-0">Country</span>
      <Select
        name="patientProfile.country"
        defaultValue={selectedCountry[0]}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
      />
      <span className="ms--12 mt-1 mx-0">State</span>
      <Field name="patientProfile.state" className="col-12 col-md-12">
        {({ field, form }) => (
          <Creatable
            {...field}
            options={options}
            isClearable
            isSearchable
            name="patientProfile.state"
            className="col-12 col-md-12 px-0"
            onChange={(option) => {
              console.log(
                "🚀 ~ file: Editprofile.js:380 ~ EditProfile ~ option:",
                option
              );
              form.setFieldValue(
                "patientProfile.state",
                option === null ? "" : option,
                true
              );
              handleState(selectedCountry, `?state=${option.value}`);
            }}
            styles={{
              control: (controlStyles) => ({
                ...controlStyles,
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                padding: "0",
                margin: "0",
              }),
            }}
          />
        )}
      </Field>
      <span
        className="ms--12 mt-1 mx-0 "
        style={{
          content: '""',
          color: "initial",
          fontSize: "initial",
          fontWeight: "initial",
        }}
      >
        City
      </span>
      <Field
        name="patientProfile.city"
        style={{
          content: '""',
          color: "initial",
          fontSize: "initial",
          fontWeight: "initial",
        }}
        className="col-12 col-md-12 "
      >
        {({ field, form }) => (
          <Creatable
            {...field}
            options={optionscity}
            isClearable
            isSearchable
            name="patientProfile.city"
            className="col-12 col-md-12 px-0"
            onChange={(option) => {
              form.setFieldValue("patientProfile.city", option === null? "" : option , true);
            }}
            styles={{
              control: (controlStyles) => ({
                ...controlStyles,
                border: "2px solid #b3c6e7",
                borderRadius: "0",
                padding: "0",
                margin: "0",
              }),
            }}
          />
        )}
      </Field>

      <FormControl
        label="Pin Code"
        type="text"
        name="patientProfile.pinCode"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      />
      {/* {({ field, form }) => ( */}

      {/* )} */}
      {/* <FormControl
        label="Country"
        type="text"
        name="patientProfile.country"
        labelColClass="col-12 justify-content-start mb-0 not-req"
        fieldColClass="col-12 w-100"
        className="mx-0"
        // disabled={isMainDataSaved || areDocsSubmitted}
      /> */}

      <Button
        style={{ margin: "12px 0", width: "100%" }}
        onClick={handleRegister}
        // disabled={!formikProps.dirty || isRegistering}
        disabled
      >
        {isRegistering ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mx-3"
          />
        ) : (
          "Save Changes"
        )}
      </Button>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default EditProfile;
