import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";

import Form from "../../form/Form";
import EditProfile from "./Editprofile";

const validationSchema = Yup.object().shape({
  patientProfile: Yup.object().shape({
    profile_type: Yup.mixed().required("Profile type is required"),
    name: Yup.string()
      .min(2, "Please enter a name with at least 2 characters.")
      .max(50, "Please enter a name with up to 50 characters.")
      .required("Name is required"),
    gender: Yup.mixed().required("Gender is required"),
    email: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .email("Invalid email format")
      .required("Email is required"),
    birth_date: Yup.date().required("Date of Birth is required"),
    address: Yup.string()
      .min(2, "Please enter a valid address with at least 2 characters.")
      .max(50, "Please enter a valid address with up to 50 characters."),
 
    pinCode: Yup.string()
      .min(6, "Please enter a 6-digit pin code number.")
      .max(6, "Please enter a valid pin code."),
    // bloodGroup: Yup.mixed().required(" is required"),
  }),
});

function EditPatientprofile(props) {
  const {
    addToExistingProfile,
    show,
    onHide,
    setChangeStep = () => {},
    setJumpToStep = () => {},
    data,
    editid,
    setupdatecalModal = () => {},
    type,
    heading = true,
  } = props;
  /* convert string to date object */
/* convert string to date object */
const dateString = data?.birth_date;
let dateobject = new Date(); // Default to current date

if (typeof dateString === "string" && dateString) {
  try {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1]?.padStart(2, "0");
      const day = parts[2]?.padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      dateobject = new Date(formattedDate);
      
      // Validate the date is valid
      if (isNaN(dateobject.getTime())) {
        dateobject = new Date();
      }
    }
  } catch (error) {
    console.log("Error parsing birth date:", error);
    dateobject = new Date();
  }
}

  const initialValues = {
    patientProfile: {
      profile_type: data?.profile_type,
      name: data?.name,
      gender: data?.gender,
      email: data?.email,
      birth_date: dateobject,
      address: data?.city,
      city: data?.city,
      state: data?.state,
      country: "India",
      pinCode: data?.pincode,
      // bloodGroup: "",
    },
  };
  const profileTypes = [
    { label: "Self", value: "self" },
    { label: "Family", value: "family" },
  ];

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];
  useEffect(() => {}, [initialValues]);
  const formikProps = useFormikContext();
  const [formNavItems, setFormNavItems] = useState([
    {
      label: "Patient Profile",
      identifier: "patientProfile",
      isActive: true,
      // element: <DoctorDetails />,
      element: EditProfile,
      elementWrapperClassName:
        "d-flex flex-column justify-content-start w-100 container px-0 me-0",
      formHeading: "Edit patient profile",
      elementProps: {
        style: { marginTop: "-8px" },
        contactNo: formikProps?.values?.patientDetails?.contactNo,
        onHide,
        setChangeStep,
        setJumpToStep,
        addToExistingProfile,
        editid,
        genderOptions,
        profileTypes,
        setupdatecalModal,
      },
    },
  ]);
  initialValues.patientProfile.profile_type = {
    label: data?.profile_type === "self" ? "Self" : "Family",
    value: data?.profile_type === "self" ? "self" : "family",
  };

  if (data?.state) {
    initialValues.patientProfile.state = {
      label: data?.state,
      value: data?.state,
    };
  }

  if (data?.city) {
    initialValues.patientProfile.city = {
      label: data?.city,
      value: data?.city,
    };
  }
  if (data?.country) {
    initialValues.patientProfile.country = {
      label: data?.country,
      value: data?.country,
    };
  }

  const genderLabels = {
    male: "Male",
    female: "Female",
    other: "Other",
  };

  initialValues.patientProfile.gender = {
    label: genderLabels[data?.gender] || "Other",
    value: data?.gender || "other",
  };

  return (
    <>
      {type === "patient" ? (
        <Form
          type="Patient Form"
          formNavItems={formNavItems}
          setFormNavItems={setFormNavItems}
          initialValues={initialValues}
          validationSchema={validationSchema}
          formPadding="px-md-1"
          heading={heading}
        />
      ) : (
        <Modal
          centered
          show={show}
          onHide={onHide}
          backdropClassName="no-patient-modal-backdrop"
          className="no-patient-modal"
        >
          <Modal.Header closeButton className="fw-bold">
            <span onClick={onHide} style={{ cursor: "pointer" }}>
              {"< Back"}
            </span>
          </Modal.Header>

          <Modal.Body className="m-0 p-0">
            <Form
              type="Patient Form"
              formNavItems={formNavItems}
              setFormNavItems={setFormNavItems}
              initialValues={initialValues}
              validationSchema={validationSchema}
              formPadding="px-md-1"
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default EditPatientprofile;
